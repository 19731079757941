<template>
  <section class="section keep-footer-off-page">
    <div class="container">
      <div class="columns is-centered">
        <div class="column box is-two-thirds p-6">
          <div class="content">
            <h1 class="heading-font has-text-centered block mt-6 mb-6">
              Privacy Policy
            </h1>
          </div>

          <p>
            Santiqi is a project aimed at exploring the possibilities of
            optimised workforce workload balancing through the advanced matching
            of the passions and skills of proffesionals to the requirements of
            hosted projects and their associated tasks.
          </p>

          <h2 class="heading block mt-6">Who can use Santiqi?</h2>
          <p>
            Santiqi is available for everyone to try. Although many of the
            design choices have been made around the tech sector there is no
            reason why this platform could not be used by anyone from any
            background.
          </p>

          <h2 class="heading block mt-6">Is Santiqi free to use?</h2>
          <p>Yes</p>

          <h2 class="heading block mt-6">Who runs Santiqi?</h2>
          <p>
            The Santiqi project is currently developed and maintained by ABP
            although hopefully in future the project can branch out into more
            community led development.
          </p>

          <h2 class="heading block mt-6">
            Where is Santiqi headed in the future?
          </h2>
          <p>Stick around and find out!</p>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: "privacy-view",
};
</script>
